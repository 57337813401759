// body {
//   margin: 0;
//   font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
//     'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
//     sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
// }

// code {
//   font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
//     monospace;
// }
.nav-logo {
  margin: auto;
}

.input-button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-color: #285666;
  margin-left: 1.5rem;
  margin-top: 5rem;
}

input.input-button {
  margin-top: 15%;
  content: "Select some files";
  display: inline-block;
  background: linear-gradient('to top', #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}

.inside-body {
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
}

.title-select-form {
  margin-top: 1rem;
  margin-right: 2rem;
  display: block;
}

.makeStyles-paper-96 {
  overflow: scroll;
}

.fields-loop {
  display: flex;
}

.csv-table {
  // overflow-x: auto;
}

.bordered-table {
  width: 100%;
  margin-left: 16px;
  display: inline-flex;
  border: 1px solid;
  justify-content: space-between;
  margin-bottom: 1rem;
}

th,
td {
  padding: 10px;
  text-align: center;
}

.table {
  border-right: 1px solid;
}

.footer-buttons-stepper {
  justify-content: space-between;
  display: flex;
}

.avatar-pharmacy {
  display: flex;

  h3 {
    margin-left: 1rem;
    margin-top: 0.2rem;
  }
}

.upload-products-button {
  margin-top: 1rem;
  margin-left: 1.5rem;
}
